@import 'SiteCore';
/*---------------- */
/*---------------- */

$deep-red: #ef4930;
$dark-gray: #231f20;
$light-gray: #d8d8d8;
$off-white: #f1f1f1;
$yellow: #d7c08b;
$site-white: #ffffff;
$site-black: #181818;
$nav-highlight: #ffaf00;
$deep-cyan: #2580a0;

html,
body {
  position: relative;
  overflow-x: hidden;
  font-family: 'Open Sans', 'Open Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}


.deep-cyan-bg {
  background-color: $deep-cyan;
}

.deep-red-bg {
  background-color: $deep-red;
}

.deep-yellow-bg {
  background-color: $yellow;
}

.deep-gray-bg {
  background-color: $dark-gray;
}

.deep-cyan {
  color: $deep-cyan;
}

.deep-red {
  color: $deep-red;
}

.deep-yellow {
  color: $yellow;
}

.deep-gray {
  color: $dark-gray;
}


/*---------------- */


#outerWrap {

  margin-left: 10px;
  margin-right: 10px;

  @media (min-width: 640px) {
    margin-left: 30px;
    margin-right: 30px;
  }
}

#homeLogo {
  display: flex;
  align-content: center;
  padding-top: 20px;
}

.home-wrap {
  display: flex;
  align-content: center;
  padding-top: 0px;
}


.home-center {
  margin: 0 auto;
  width: 700px;
}


/*---------------- */

.hero-wrap {
  overflow: hidden !important;
  position: relative !important;

  height: 330px;
  width: 100%;

  @media (min-width: 320px) {
    height: 330px;
  }

  @media (min-width: 375px) {
    height: 310px;
  }

  @media (min-width: 440px) {
    height: 420px;
  }

  @media (min-width: 640px) {
    height: 520px;
  }

  @media (min-width: 728px) {
    height: 542px;
  }

  @media (min-width: 800px) {
    height: 543px;
  }

  @media (min-width: 1024px) {
    height: 560px;
  }

  @media (min-width: 1200px) {
    height: 710px;
  }
}

img.hero-img {
  height: 300px;
  @media (min-width: 320px) {
    height: 300px;
  }

  @media (min-width: 375px) {
    height: 300px;
  }

  @media (min-width: 440px) {
    height: 390px;
  }

  @media (min-width: 640px) {
    height: 510px;
  }

  @media (min-width: 728px) {
    height: 538px;
  }


  @media (min-width: 800px) {
    height: 540px;
  }

  @media (min-width: 1024px) {
    height: 540px;
  }

  @media (min-width: 1200px) {
    height: 700px;
  }

}


img.gears-img {
  height: 400px;
  @media (min-width: 320px) {
    height: 400px;
  }


  @media (min-width: 440px) {
    height: 520px;
  }

  @media (min-width: 640px) {
    height: 680px;
  }

  @media (min-width: 728px) {
    height: 720px;
  }


  @media (min-width: 800px) {
    height: 728px;
  }

  @media (min-width: 1024px) {
    height: 730px;
  }

  @media (min-width: 1200px) {
    height: 940px;
  }

}

.hero-mask {
  position: absolute;
  overflow: hidden;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-gears {
  position: absolute;
  overflow: hidden;
  z-index: 200;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.heroBG {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


}


.nav-wrapper {
  min-width: 300px !important;
}

/*---------------- */

.home-nav {
  padding-left: 0;
  text-align: left;
  font-size: 24px;
  font-family: 'Open Sans', 'Open Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  min-width: 280px;

  ul {
    list-style: none;
    line-height: 145%;
  }

  li {
    cursor: pointer;
  }

  .active {
    font-weight: bold;
    color: #ffaf00;
  }
}

.home-nav-fixed {
  position: fixed;
  top: 163px;
}


/*---------------- */

.section {

  .content-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;

    @media (min-width: 728px) {
      flex-direction: row;
      gap: 10px;
    }
  }

  /* **************************** */
  .small-box-row {
    display: flex;
    padding-top: 10px;
    align-content: center;

  }

  .small-box {
    flex-basis: 140px;
    display: flex;
    align-items: center;
    height: 60px;

    @media (min-width: 728px) {
      flex-basis: 260px;
      height: 120px;
    }
  }

  .small-box-icon > img {

    height: 108px;

  }

  .small-box-title {
    font-family: 'Open Sans', 'Open Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: larger;
    color: $site-black;
  }


  /* ******************** */

  .content-cell {

    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 728px) {
    }

  }

  .content-column {
    flex-direction: column;
    gap: 10px;
  }


  .home-text {
    line-height: 180%;
    font-family: 'Open Sans', 'Open Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 22px;
    text-align: left;
    color: $site-black;


    @media (min-width: 340px) {
      font-size: 20px;
      padding: 15px 15px 10px 20px;
    }

    @media (min-width: 600px) {
      min-width: 400px;
    }

    @media (min-width: 800px) {
      min-width: 500px;
      padding-top: 30px;
    }

    @media (min-width: 1024px) {
      min-width: 600px;
      max-width: 800px;
    }
   > a {
      font-weight:bold;
     color:#1d3557;
    }
  }


}


/*---------------- */

.home-wrap-scrollable {
  //position: relative !important;
}

.home-wrap-fixed {
  position: fixed !important;
}


/*---------------- */

.outer-static-wrap {
  position: static;
}


/*
@supports (-webkit-touch-callout: none) {
    .hero:after {
        background-size: auto 100vh;
    }
}
*/

.hero-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4em;
  color: $site-black;
  text-shadow: 1px 2px #000;
  text-align: left;

  @media (min-width: 375px) {
    font-size: 2.2em;
  }

  @media (min-width: 600px) {
    font-size: 3.2em;
  }

  @media (min-width: 1024px) {
    font-size: 4.4em;
  }

  @media (min-width: 1200px) {
    font-size: 5em;
  }
}


#heroSpot1 {
  position: absolute;
  opacity: 1;
  left: -100%;
  height: 100px;


  @media (min-width: 375px) {
    min-width: 375px;
    top: 350px;
  }

  @media (min-width: 600px) {
    top: 350px;
    min-width: 500px;
  }

  @media (min-width: 1024px) {
    min-width: 800px;
    top: 400px;
  }

  @media (min-width: 1200px) {
  }
}


#heroSpot2 {
  position: absolute;
  opacity: 1;
  left: -100%;

  height: 100px;
  left: 100%;


  @media (min-width: 375px) {
    min-width: 375px;
    top: 400px;
  }

  @media (min-width: 600px) {
    top: 410px;
    min-width: 600px;
  }

  @media (min-width: 1024px) {
    min-width: 800px;
    top: 500px;
  }

  @media (min-width: 1200px) {
  }
}

#heroSpot3 {
  position: absolute;
  opacity: 1;
  left: -100%;
  height: 100px;


  @media (min-width: 375px) {
    min-width: 375px;
    top: 450px;
  }

  @media (min-width: 600px) {
    top: 470px;
    min-width: 600px;
  }

  @media (min-width: 1024px) {
    min-width: 800px;
    top: 600px;
  }

  @media (min-width: 1200px) {
  }
}


/*---------------- */

.seaplane-div {
  position: relative;
  top: 20px;
  left: 100%;
  height: 100px;
  width: 220px;
  z-index: 20;
  >img {
    width:120px;
  }
  @media (min-width: 320px) {
    > img {
      width: 120px;
    }
  }

  @media (min-width: 375px) {
    > img {
      width: 120px;
    }
  }

  @media (min-width: 768px) {
    > img {
      width: 160px;
    }
  }

  @media (min-width: 800px) {
    > img {
      width: 180px;
    }
  }

  @media (min-width: 1024px) {
    > img {
      width: 150px;
    }
  }

  @media (min-width: 1200px) {
    > img {
      width: 250px;
    }
  }
}

/*---------------- */

.seaplane-front-div {
  position: absolute;
  z-index: 100;

  top: 70%;
  > img {
    width: 220px;
  }

  @media (min-width: 320px) {

    > img {
      width: 220px;
    }
  }

  @media (min-width: 375px) {
    > img {
      width: 220px;
    }

    top: 73%;
  }

  @media (min-width: 440px) {
    > img {
      width: 260px;
    }

    top: 71%;
  }
  @media (min-width: 600px) {
    > img {
      width: 320px;
    }

    top: 73%;
  }

  @media (min-width: 800px) {
    > img {
      width: 350px;
    }

    top: 76%;
  }

  @media (min-width: 1200px) {
    > img {
      width: 400px;
    }

    top: 75%;
  }
}

/*---------------- */

.seaplane-front-slide-in {
  opacity: 1;
  animation-duration: 2s;
  animation-name: lakeslide;
  animation-fill-mode: forwards;
}

@keyframes lakeslide {
  from {
    scale: 70%;
    left: -550px;
  }

  to {
    scale: 100%;
    left: 18.22%;
  }
}


/*---------------- */
.seaplane-animation {
  animation-name: flightplan;
  animation-fill-mode: backwards;
  animation-timing-function: linear;
  animation-duration: 3.5s;

  @media (min-width: 375px) {
    animation-duration: 3.5s;
  }

  @media (min-width: 475px) {
    animation-duration: 3.5s;
  }

  @media (min-width: 600px) {
    animation-duration: 4s;
  }

  @media (min-width: 800px) {
    animation-duration: 3.5s;
  }

  @media (min-width: 1024px) {
    animation-duration: 3.6s;
  }

  @media (min-width: 1200px) {
    animation-duration: 3.7s;
  }
}


@keyframes flightplan {
  from {
    top: 80px;
    left: 100%;
  }

  to {
    top: 206px;
    left: -100%;
  }
}


/*---------------- */
.fade-in-1s {
  animation-duration: 1s;
  animation-name: fadeo;
  animation-fill-mode: forwards;
}

@keyframes fadeo {
  from {
    opacity: .66;
  }

  to {
    opacity: 1;
  }
}

/*---------------- */
.fade-to-full {
  animation-duration: 1s;
  animation-name: fadefull;
  animation-fill-mode: forwards;
}

@keyframes fadefull {
  from {
    opacity: inherit;
  }

  to {
    opacity: 1;
  }
}

/*---------------- */

.fade-to-60 {
  animation-duration: 1s;
  animation-name: fade60;
  animation-fill-mode: forwards;
}

@keyframes fade60 {
  from {
    opacity: 1;
  }

  to {
    opacity: .68;
  }
}

/*---------------- */

.fade-0-to-60 {
  animation-duration: 1.4s;
  animation-name: fadein60;
  animation-fill-mode: forwards;
}

@keyframes fadein60 {
  from {
    opacity: 0;
  }

  to {
    opacity: .24;
  }
}

/*---------------- */
.fade-out {
  animation-duration: 1.4s;
  animation-name: fadeout;
  animation-fill-mode: forwards;
}

@keyframes fadeout {
  from {
    opacity: inherit;
  }

  to {
    opacity: 0;
  }
}

/*---------------- */

.slide-from-left {
  animation-fill-mode: forwards;

  @media (min-width: 375px) {
    animation-duration: 1.1s;
    animation-name: slideinleft375;
  }

  @media (min-width: 600px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    animation-duration: 1.9s;
    animation-name: slideinleft1200;
  }
}


@keyframes slideinleft375 {
  from {
    left: -100%;
  }

  to {
    left: 20px;
  }
}

@keyframes slideinleft1200 {
  from {
    left: -100%;
  }

  to {
    left: 18.22%;
  }
}


/*---------------- */

.slide-from-right {


  animation-fill-mode: forwards;

  @media (min-width: 375px) {
    animation-duration: 1.1s;
    animation-name: slideinright375;
  }

  @media (min-width: 600px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    animation-duration: 1.9s;
    animation-name: slideinright1200;
  }
}


@keyframes slideinright375 {
  from {
    left: 100%;
  }

  to {
    left: 20px;
  }
}

@keyframes slideinright1200 {
  from {
    left: 100%;
  }

  to {
    left: 18.22%;
  }
}

/*---------------- */

/* Rotation of gears */


.gear-rotate {

  animation-duration: 45s;
  animation-name: spin;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/*---------------- */


#socials {
  text-align: center;
  padding-top: 5px;

  @media only screen and (min-width: 768px) {
    padding-right: 0px;
    padding-top: 10px;
    text-align: right;
  }

  .social-icon {
    max-width: 22px;
    margin: 6px;

    @media (min-width: 640px) {
    }

    @media (min-width: 768px) {
      max-width: 18px;
    }

    @media (min-width: 960px) {
    }

    @media (min-width: 1024px) {
      margin: 4px;
    }
  }
}


.scroller-div {
  // height: 4000px;
}


.home-logo {
  @media (min-width: 320px) {
    > img {
      width: 320px;
    }
  }

  @media (min-width: 375px) {
    > img {
      width: 330px;
    }
  }

  @media (min-width: 600px) {
    > img {
      width: 330px;
    }
  }

  @media (min-width: 1024px) {
    > img {
      width: 350px;
    }
  }
}