@import "./site-vars";

.font-main {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.font-semi-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.normal-letter-space {
  letter-spacing: normal;
}

.font-bold {
  font-weight: bold;
}

.ital {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
}

.site-text {
  font-size: 1em;
}

.bold-title {
  font-weight: bold;
}

.small-text {
  font-size: 0.85em;
}

.big-text {
  font-size: 28px;

  @media screen and (min-width: 320px) {
    font-size: 24px;
  }

  @media screen and (min-width: 900px) {
    font-size: 28px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;

  }
}

