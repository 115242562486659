@import '../../scss/index';


.hideForm {
  display: none;
}

.showForm {
  display: block;
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: smaller;
}

.error-text {
  color: $error-color;
  font-size: xx-small;
  margin-top: 0.4rem;
  display: inline-block;
}