@import "./site-vars";

body {
  margin: 0;
  background-color: $site-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}


.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.block {
  display: block;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-display {
  display: flex;
}

.zero-opacity {
  opacity: 0;
}

.inline {
  display: inline;
}

.r-img {
  max-width: 100%;
  height: auto;
}

.pointer {
  cursor: pointer;
}