@import "./site-vars";

.formRowFull {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  margin-top: 2px;
  overflow: auto;
}


/** main form style for inputs*/

.formRow {

  margin-bottom: 2px;
  margin-top: 2px;
  margin-left:5px;
  padding: 5px;
  width: 100vw;
  color: $primary-color;

  label {
    font-weight: bold;
    display: block;
  }

  input {
    padding: 4px;
    box-shadow: 3px 3px 5px grey;
    font-family: 'Open-sans', sans-serif;
    font-weight: 600;
    width: 300px;
    color: $primary-color;
  }

  option {
    padding: 4px;
    box-shadow: 3px 3px 5px grey;
    font-family: 'Open-sans', sans-serif;
    font-weight: 600;

  }

  select {
    padding: 4px;
    padding-left:10px;
    box-shadow: 3px 3px 5px grey;
    font-family: 'Open-sans', sans-serif;
    font-weight: 600;
    width: 200px;
    color: $primary-color;
  }

  @media only screen and (min-width: 800px) {
    max-width: 700px;
  }
  @media only screen and (min-width: 800px) {
    max-width: 800px;
    label {
      font-weight: bold;
      width: 160px;
      float: left !important;

    }
  }
}


.formCol {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  margin-top: 2px;

  @media only screen and (min-width: 800px) {

  }
}


.formCheckList {
  width: 250px !important;
}

.formCheckRowFull {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  margin-top: 2px;
  overflow: auto;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}


//
.appForms {

  label,
  input {
    color: $primary-color;
  }

  select {
    color: $primary-color;
    border-width: 2px;
    border-color: $primary-color;
    border-radius: 4px;
  }

  label {
    margin-bottom: 0.3rem;

  }

  input {
    border: 2px solid rgba($primary-color, 0.8);
    border-radius: 4px;
    padding: 0.2rem .2rem;
    padding-left: 4px;
    width: 220px !important;
    margin: .4em;
  }
}


button.app-btn {
  color: $site-white;
  width: 100px;
  font-size: 1rem;
  background: $primary-color;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-top: 4px;

  @media only screen and (min-width: 800px) {

  }

  &:hover {
    background: rgba($primary-color, 0.8);
  }

  button.back {
    margin-right: 1rem;
    padding: 0.3rem;
    cursor: pointer;
  }

  .input-error {
    border-color: $error-color;
    font-size: xx-small;
  }

  .error {
    color: $error-color;
    font-size: xx-small;
    margin-top: 0.3rem;
  }

  .disabledBtn {
    cursor: not-allowed;
    background: rgba($primary-color, 0.2);
  }

  input[type="checkbox"] {
    margin-right: 0.7rem;
  }

  .steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: smaller;
  }

  .error-text {
    color: $error-color;
    font-size: xx-small;
    margin-top: 0.4rem;
    display: inline-block;
  }

  .hideForm {
    display: none;
  }

  .showForm {
    display: flex;
  }
}


.nav-cell {
  display: flex;
}

a.app-link {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  cursor:pointer;
  color: #fff;
  @media only screen and (min-width: 1200px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 18px;
  }
}

a.app-link.dark {
  color:$primary-color;
}

.app-btn {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  background-color: $primary-color;
  color: $site-white !important;
  padding: 14px;
  box-sizing: border-box;
  border-top: 1px solid #c2c0c0;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #c4c4c4;
  min-width: 100px;
  max-width: 300px;
  cursor: pointer;
  margin: 5px;
  border-radius: 4px;

}

.app-btn:hover {
  background-color: $accent-color;
}

.small-btn {
  width: 140px;
}

/***********************  FORMS */
.fixed-height-box {
  height: 300px;
}

.forms {
  label,
  input {
    color: $primary-color;
    // margin:.5em;
  }

  select {
    color: $primary-color;
    border-width: 2px;
    border-color: $primary-color;
    border-radius: 4px;
    padding: 5px;
    margin-left: 0px;
    font-size: 1em;
  }

  label {
    font-size: 1rem;
    margin-bottom: 0.3rem;

  }

  input {
    border: 2px solid rgba($primary-color, 0.8);
    border-radius: $border-radius;
    padding: 0.5rem .5rem;
    padding-left: 1rem;
    font-size: 1rem;
    width: 250px !important;
  }
}

.app-btn-icon {
  width: 200px;
  padding: 10px;

  > img {
    margin-bottom: -10px;
    padding-right: 2px;
  }
}

.use-pointer {
  cursor: pointer;
}

.form-grid {
  display: grid;

  width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: 220px 1fr;
  @media only screen and (min-width: 800px) {
    display: grid;
    grid-column-gap: 20px;
    width: 800px;
    grid-template-columns: 240px 1fr 1fr;
  }

}