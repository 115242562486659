$deep-red: #ef4930;
$dark-gray: #231f20;
$light-gray: #d8d8d8;
$off-white: #f1f1f1;
$yellow: #d6a228;
$site-white: #ffffff;
$site-black: #181818;
$nav-highlight: #ffaf00;
$error-color: #b33030;
$primary-color: #1d3557;
$accent-color:#ffaf00;
$border-radius: '4px';