@import "./site-vars";
a:link {
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: $site-black;
}

a:active,
a:focus,
a:hover {
  color: #ffaf00;
  outline: 0;
  text-decoration: underline;
  cursor: pointer;
}

a:visited {
  color: $site-black;
}

a.site-nav:link {
  text-decoration: none;
}

a.site-nav:active {
  text-decoration: none;
}

a.site-nav:hover {
  color: $nav-highlight;
  text-decoration: none;
}

a.site-nav:visited {
  text-decoration: none;
}

a.nav-active {
  &:link {
    color: $nav-highlight;
    text-decoration: none;
  }

  &:hover {
    color: $nav-highlight;
    text-decoration: none;
  }

  &:active {
    color: $nav-highlight;
    text-decoration: none;
  }

  &:visited {
    color: $nav-highlight;
    text-decoration: none;
  }
}

a.dark-text:link {
  color: $site-black;
  text-decoration: none;
}

a.dark-text:active {
  color: $nav-highlight;
  text-decoration: none;
}

a.dark-text:hover {
  color: $nav-highlight;
  text-decoration: none;
}

a.dark-text:visited {
  color: $site-black;
  text-decoration: none;
}

button.site-nav {
  text-decoration: none;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  background-color: $site-black;
  border-style: none;
  color: $site-white;
}

button.site-nav:hover {
  color: $nav-highlight;
}
