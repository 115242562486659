@import "./site-vars";

.page {

  display: flex;
  flex-direction: column;
}

.page-top-buffer {
  padding-top: 108px;

}

.page-top-header {
  padding-left: 30px;
}

.page-row {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.page-cell {
  display: flex;
  flex-direction: column;

}

.page-date-day {

}

.page-date-month {

}

.page-title {
  display: flex;
  font-size: large;
  font-weight: bold;
  justify-content: flex-start;
  padding-top: 10px;
}

.page-navbar {
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-nav {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  background-color: $nav-highlight;
  color: $accent-color;
  padding: 3px;
  box-sizing: border-box;
  border-top: 1px solid #c2c0c0;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #c4c4c4;
  min-width: 100px;
  max-width: 150px;
  cursor: pointer;
  margin: 5px;
  border-radius: 2px;
}

.page-nav:hover {
  background-color: $accent-color;
  color: $site-white;
}


.page-item {
  max-width: 400px;
}

.page-item:hover {
  background-color: $nav-highlight;
}