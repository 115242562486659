


.pix-vspace-10 {
  height: 10px;
}

.pix-vspace-50 {
  height: 100px;
  background-color: #FFAF00;
}


/* COLOR */

.off-black-bg {
  background-color: $site-black;
}

.highlight {
  color: $nav-highlight;
}

.gray {
  background-color: $light-gray;
}

.light-bg {
  background-color: $off-white;
}

.dark-bg {
  background-color: $site-black;
}

.off-white {
  color: $off-white;
}

.site-white {
  color: $site-white;
}

.content-container {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.black {
  color: #000;
}


/* TYPO */

/** PADDING */

.padded-1 {
  padding: 1em;
}

.padded-2 {
  padding: 2em;
}

.padtop-1 {
  padding-top: 1em;
}


.btn-primary {
  background-image: none !important;
  background-color: #ffaf00 !important;
  color: $site-black !important;
  padding: 0.3em;
}

.btn-primary:hover {
  background-color: $site-white !important;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.no-display {
  display: none;
}

footer {
  background-color: #d8d8d8;
  text-align: center;
  padding: 2px;
  padding-top: 20px;
  height: 110px;
}

.align-left {
  text-align: left !important;
  margin-left: 0 !important;
}

.bg-test {
  background-color: #ffaf00;
}