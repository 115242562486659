@import "./site-vars";

.logo-on-white-200 {

  display: none;
  @media screen and (max-height: 420px) and (orientation: landscape) {
    display: none;
  }

  @media screen and (max-width: 550px) and (orientation: portrait) {
    display: none;
  }

  @media screen and (min-width: 800px) and (min-height: 600px) {

    display: flex;
    margin-left: 10px;
    margin-top: 10px;
    > img {
      width: 260px;
      height: auto;
    }
  }

}

.logo-on-white-small {

  width: 200px;

  > img {
    width: 200px;
    height: auto;
  }

}
