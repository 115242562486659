@import 'index';

.fixed-nav-bar {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 50px;
  overflow: hidden;
  background-color: $site-black;
}

.nav-bg-wrap {
  position: relative;
  width: 100%;
  height: 55px;
}

.nav-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 46px;
  background-color: #000;
  opacity: 0;
}

.nav-bg-banner-extend {
  animation-fill-mode: forwards;
  animation-duration: .35s;
  animation-name: bannerextend;

}

.nav-bg-banner-retract {

  animation-duration: .35s;
  animation-name: bannerretract;
  animation-fill-mode: forwards;

}

@keyframes bannerextend {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

@keyframes bannerretract {
  from {
    top: 0px;
    opacity: 1;
  }

  to {
    top: -100px;
    opacity: 0;
  }
}

.test-cell {
  position: absolute;
  top: 10px;
  left: 350px;
}

.nav-logo-link {
  > img {
    padding-top: 10px;
    width: 230px;
  }

  @media (min-width: 375px) {
    > img {
      width: 200px;
    }
  }

  @media (min-width: 600px) {
    > img {
      width: 220px;
    }
  }

  @media (min-width: 1024px) {
    > img {
      width: 240px;
    }
  }
}

.logo-white {
  text-align: center;
  cursor: pointer;

  > img {
    width: 180px;
    height: auto;
  }

}