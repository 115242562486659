// formCard
@import "./site-vars";

$border-radius: 4px;


.formCard {
  border: 0px solid rgba(29, 53, 87);
  padding: 0px;
  overflow: hidden;
  border-radius: $border-radius;
  //box-shadow: 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25), 0 0 #0000;
  background: white;
  min-height: 70vh;
  min-width: 50vw;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 800px) {
    max-width: 460px;
  }

}



.formCheckWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.formCheckCol {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  margin-top: 2px;


}

