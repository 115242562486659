@import "../../scss/index";

// override site-vars here
$primary-color: #1d3557;
$error-color: #e63946;
$bg-color: #f1faee;
$text-color: #293241;
$color-white: #fff;

$border-radius: 4px;



.container {
  padding: 3rem;
  width: 600px;
  border-radius: $border-radius;
}

.formCard {
  //border: 2px solid rgba(29, 53, 87);
  padding: 5px;
  //border-radius: $border-radius;
  //box-shadow: 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25), 0 0 #0000;
  background: $site-white;
  margin-top:100px;

  h1 {
    font-size: 2rem;
    color: $text-color;
    margin-bottom: 2rem;
  }

  h2 {
    color: $text-color;
    margin-bottom: 1rem;
  }

  label,
  input {
    color: $text-color;
  }

  label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  input {
    border: 2px solid rgba($primary-color, 0.8);
    border-radius: $border-radius;
    padding: 0.5rem .5rem;
    font-size: 1rem;
  }

  select
 {
    border: 2px solid rgba($primary-color, 0.8);
    border-radius: $border-radius;
    padding: 0.5rem .6rem;
    font-size: 1rem;
  }

  .formRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  button {
    color: $color-white;
    width: 200px;
    font-size: 1.4rem;
    background: $primary-color;
    border-radius: $border-radius;
    border: none;
    cursor: pointer;
    padding: 0.8rem 1.2rem;
    margin-top: 0.5rem;

    &:hover {
      background: rgba($primary-color, 0.8);
    }
  }

  button.back {
    margin-right: 1rem;
    padding: 0.3rem;
    cursor: pointer;
  }

  .input-error {
    border-color: $error-color;
    font-size: xx-small;
  }

  .error {
    color: $error-color;
    font-size: xx-small;
    margin-top: 0.3rem;
  }

  .disabledBtn {
    cursor: not-allowed;
    background: rgba($primary-color, 0.2);
  }

  input[type="checkbox"] {
    margin-right: 0.7rem;
  }

  .steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: smaller;
  }

  .error-text {
    color: $error-color;
    font-size: xx-small;
    margin-top: 0.4rem;
    display: inline-block;
  }

  .hideForm {
    display: none;
  }

  .showForm {
    display: block;
  }
}


